import { Button, Col, Form, FormInstance, Row, Select, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import { CompanyCatalogModel } from '@stores/companyCatalogStore';
import companyStore from '@stores/companyStore/companyStore';
import dictStore, { SourceModel } from '@stores/dictStore/dictStore';
import userStore from '@stores/userStore/userStore';

interface CompanyCatalogCreatorProps {
  onFinish(values);
  companyCatalog?: CompanyCatalogModel;
  isBusy: boolean;
}

interface CompanyCatalogCreatorState {
  sources: SourceModel[];
}

class CompanyCatalogCreator extends Component<CompanyCatalogCreatorProps, CompanyCatalogCreatorState> {
  constructor(props: CompanyCatalogCreatorProps) {
    super(props);

    this.state = {
      sources: [],
    };
  }

  form: FormInstance;

  componentDidMount() {
    if (this.isAdmin) {
      dictStore.loadSourcesDict().then((ans) => {
        this.setState({ sources: ans.filter((it) => it.catalogSupport) });
      });
    }
  }

  get isAdmin() {
    return ['admin', 'tech'].includes(userStore.userData.roleCode);
  }
  render() {
    const props = this.props;
    const type = this.form ? this.form.getFieldValue('type') : props.companyCatalog ? props.companyCatalog.type : null;

    const init = props.companyCatalog
      ? {
          title: props.companyCatalog.title,
          description: props.companyCatalog.description,
          type: props.companyCatalog.type,
          foreignId: props.companyCatalog.foreignId,
          sourceName: props.companyCatalog.sourceName,
          billingProfileId: props.companyCatalog.billingProfileId,
        }
      : null;
    return (
      <Spin spinning={props.isBusy}>
        <Form
          onFinish={(e) => {
            props.onFinish(e);
          }}
          onFinishFailed={console.log}
          name={'catalogForm'}
          layout={'vertical'}
          initialValues={init}
          ref={(e) => (this.form = e)}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24}>
              <Form.Item name="title" rules={[{ required: true, message: 'Введите название' }]}>
                <FloatInput placeholder="Название" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item name="description">
                <FloatInput placeholder="Описание" />
              </Form.Item>
            </Col>
            {this.isAdmin && (
              <Col xs={24}>
                <Form.Item name="type">
                  <FloatSelect placeholder="Тип" onChange={() => this.forceUpdate()}>
                    <Select.Option value={'Default'}>Не выбран</Select.Option>
                    <Select.Option value={'Rent'}>Аренда</Select.Option>
                    <Select.Option value={'Foreign'}>Подход</Select.Option>
                    <Select.Option value={'Itinerary'}>Рейсовый</Select.Option>
                  </FloatSelect>
                </Form.Item>
              </Col>
            )}
            {this.isAdmin && (
              <Col xs={24}>
                <Form.Item name="foreignId">
                  <FloatInput placeholder="Внешний идентификатор" disabled={type !== 'Foreign'} />
                </Form.Item>
              </Col>
            )}
            {this.isAdmin && (
              <Col xs={24}>
                <Form.Item name="sourceName">
                  <FloatSelect placeholder="Источник" disabled={type !== 'Foreign'}>
                    {this.state.sources.map((s) => (
                      <Select.Option value={s.name} key={s.name}>
                        {s.name}
                      </Select.Option>
                    ))}
                  </FloatSelect>
                </Form.Item>
              </Col>
            )}
            {this.isAdmin && (
              <Col xs={24}>
                <Form.Item name="billingProfileId">
                  <FloatSelect placeholder="Профиль биллинга">
                    {companyStore.billingProfiles.map((s) => (
                      <Select.Option value={s.billingProfileId} key={s.billingProfileId}>
                        {s.billingProfileName}
                      </Select.Option>
                    ))}
                  </FloatSelect>
                </Form.Item>
              </Col>
            )}
            <Col xs={24}>
              <Form.Item>
                <Button type="primary" form={'catalogForm'} htmlType="submit" loading={props.isBusy}>
                  Сохранить
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  }
}

export default observer(CompanyCatalogCreator);
